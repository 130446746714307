/* 기본 스타일 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0; 
  background-color: var(--background-color);
  color: var(--text-color);
}

body.dark {
  --background-color: #2b2a2a;
  --text-color: #ffffff;
}

body.light {
  --background-color: #ffffff;
  --text-color: #000000;
}

.app-container {
  display: flex;
}

.main-content {
  flex-grow: 1;
  transition: margin-left 0.3s ease;
}

.main-content.shifted {
  margin-left: 0;
}

.ChatBoxFixed {
  position: fixed;
  bottom: 0;
  width: calc(100% - 40px);
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.ChatList {
  padding: 20px;
  overflow-y: scroll;
  margin-left: 25%;
  margin-right: 25%;
  max-height: 80vh;
}

.ChatList::-webkit-scrollbar {
  width: 8px;
}

.ChatList::-webkit-scrollbar-track {
  background: transparent;
}

.ChatList::-webkit-scrollbar-thumb {
  background-color: #D3D3D3;
  border-radius: 10px;
}

.ChatList::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media screen and (max-width: 768px) {
  .ChatList {
    margin-left: 3%;
    margin-right: 3%;
  }

  .sidebar {
    display: none; /* 모바일 화면에서는 사이드바 숨기기 */
  }

  .sidebar.open {
    display: block; /* 모바일 화면에서 사이드바가 열릴 때 표시 */
    position: fixed;
    top: 0;
    left: 0;
    width: 30dvh;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow-y: hidden;
  }

  .sidebar-overlay {
    display: none; /* 모바일 화면에서 사이드바 오버레이 숨기기 */
  }

  .sidebar-overlay.open {
    display: block; /* 모바일 화면에서 사이드바가 열릴 때 오버레이 표시 */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
}
 