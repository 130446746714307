/* Tailwind base */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --button-color: #000000;
  --my-chat-bubble-color: #F0F0F0;
  --my-chat-text-color: #000000;
  --other-chat-bubble-color: #DCF8C6;
  --other-chat-text-color: #000000;
  --chat-container-bg-color: #FFFFFF;
  --chat-bubble-bold: normal;
  --chat-bubble-shadow: none;
  --time-bold: normal;
  --chatbox-bg-color: #f0f0f0; /* 기본 ChatBox 배경 색상 */
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.main-section {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}


.main-content {
  position: relative;
  width: 100%;
  transition: margin-left 0.3s ease;
}

.main-content.shifted-right {
  margin-left: 80px; /* 사이드바 너비만큼 오른쪽으로 이동 */
}

.grid-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.grid-item {
  box-sizing: border-box;
  background: var(--chat-container-bg-color);
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.grid-item.no-border {
  border: none;
}

.grid-drag-handle {
  cursor: move;
  padding: 10px;
  background: #d1d5db;
  border-bottom: 1px solid #e5e7eb;
  text-align: center;
}

.chat-list-container {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  margin: 0;
  padding: 10px;
}

.chat-box-container {
  overflow-y: hidden;
  flex: none;
  height: 10%;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
}

.chat-box-container .chat-container {
  background: var(--chatbox-bg-color); /* ChatBox 배경 색상 */
}

.edit-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.layout-info {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  background-opacity: 0.8;
  padding: 10px;
  font-size: 0.875rem;
  z-index: 50;
}


.show-scrollbar {
  overflow: auto !important;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #9ca3af;
  z-index: 10;
}

.react-resizable-handle-s {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  cursor: s-resize;
}

.react-resizable-handle-e {
  right: 0;
  top: 50%;
  margin-top: -10px;
  cursor: e-resize;
}

.react-resizable-handle-w {
  left: 0;
  top: 50%;
  margin-top: -10px;
  cursor: w-resize;
}

.react-resizable-handle-n {
  top: 0;
  left: 50%;
  margin-left: -5px;
  cursor: n-resize;
}

.grid-item:not(.edit-mode) .react-resizable-handle {
  display: none;
}

.grid-item.edit-mode.chat-container .react-resizable-handle {
  display: block;
}

.grid-item.edit-mode {
  border: none;
}

/* Settings Dropdown */
.settings-container {
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 1100;
}

.settings-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1200;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  padding: 10px 20px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
}

.dropdown-menu button:hover {
  background-color: #f0f0f0;
}

.header-container {
  display: flex;
  align-items: center;
  position: fixed;
  top: 15px;
  left: 60px;
  z-index: 1100;
  transition: left 1.3s ease;
}

.header-container.shifted-header {
  margin-left: 18%; /* Adjust this value based on your sidebar width */
}

.brand-text {
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}



.login-container {
  position: fixed;
  top: 15px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 1100;
}

.login-button {
  background-color: grey;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.login-button:hover {
  background-color: black;
}

.settings-container .save-button,
.settings-container .cancel-button,
.settings-container .reset-button { 
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
}

.settings-container .save-button:hover,
.settings-container .cancel-button:hover,
.settings-container .reset-button:hover {
  background-color: #e0e0e0;
}