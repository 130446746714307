/* MainPage.css */
@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@800&display=swap');

.main {
    display: flex;
    width: 100%;
    height: 100vh; 
    position: relative;
    background-color: #0d0d0d;
    overflow: hidden;
    text-align: left;
    font-size: 2rem;  
    color: #fff;
    font-family: 'Pretendard', sans-serif;
}

.mainChild {
    display: flex;
    justify-content: center;  
    align-items: center;  
    transition: transform 0.5s ease-in-out, width 0.5s ease-in-out;  
    width: 100%; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.mainChild.shrink {
    transform: translate(-70%, -50%);  
    width: 70%; 
}

.branchBl1 {
    width: 50vw;
    object-fit: cover;
}

.mainItem {
    background-color: #f6f6f6;
    width: 30%;
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    transform: translateX(100%);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.mainItem.visible {
    opacity: 1;
    transform: translateX(0);
}

.div {
    letter-spacing: 0.02em;
    line-height: 156.5%;
    font-weight: 800;
}

.wrapper, .component3 {
    margin-top: 20px; 
    box-shadow: 0px 4px 30px rgba(38, 58, 67, 0.15);
    border-radius: 12px;
    background-color: #b5b5b6;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    width: 80%; 
    box-sizing: border-box;
    font-family: 'Pretendard', sans-serif;
}

.wrapper.visible, .component3.visible {
    opacity: 1;
}

.wrapper {
    margin-bottom: 30px;
}

.component3 {
    margin-top: 30px;
}
