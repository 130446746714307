/* Login.css */
@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@800&display=swap');

.loginPage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-family: Pretendard;
}

.prlogo2-1-icon { 
  position: relative;
  width: 20.8vw;
  overflow: hidden; 
  object-fit: cover;
  margin-bottom: 10vh ;
}

.form { 
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
 
.inputGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;  
}
 
.inputField { 
  height: 4.375rem;
  position: relative;
  padding: 10px;
  border: 0.6px solid #b1b1b1;
  box-sizing: border-box;
  border-radius: 6px;
  font-size: 1rem;
}

.inputField:focus, .inputField:not(:placeholder-shown) {
  outline: none;
}

.inputField:focus + .underline, .inputField:not(:placeholder-shown) + .underline {
  height: 5.71%;
  width: 100%;
  position: absolute;
  top: 94.29%;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0px 0px 5px 5px;
  background-color: #007f3a;
}


.error {
  color: red;
  margin-bottom: 10px;
}

.LoginButton, .SignupButton {
  width: 20.8vw;
  position: relative;
  box-shadow: 0px 4px 30px rgba(38, 58, 67, 0.15);
  border-radius: 12px;
  background-color: #b5b5b6;
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 165px;
  box-sizing: border-box;
  text-align: left;
  font-size: 1.4375rem;
  color: #fff;
  white-space: nowrap;
  font-weight: 600;
  font-family: Pretendard;
}
.LoginButton:hover, .SignupButton:hover {
  background-color: #111111;
}
.LoginButton{
  margin-top: 2rem; 
} 

.or {
  margin: 20px 0; 
  width: 28px;
  position: relative;
  font-size: 30px;
  font-family: Pretendard;
  color: #000;
  text-align: left;
  display: inline-block;
}
 

.label {
  width: 100%;
  position: relative;
  font-size: 1.6rem;
  line-height: 156.5%;
  font-weight: 600;
  font-family: Pretendard;
  color: #263a43;
  text-align: left;
  display: block; 
  margin-bottom: 10px; 
}