/* 채팅 입력 컨테이너 */
.chat-input-container {
  display: flex; /* 자식 요소를 플렉스 박스로 배치 */
  justify-content: center; /* 자식 요소를 수평 중앙 정렬 */
  align-items: center; /* 자식 요소를 수직 중앙 정렬 */
  width: 100%; /* 너비를 100%로 설정 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 박스 크기를 계산 */
  position: relative; /* 자식 요소의 위치를 상대적으로 설정 */   
}

/* 텍스트 영역 래퍼 */
.textarea-wrapper {
  position: relative; /* 자식 요소의 위치를 상대적으로 설정 */
  width: 100%; /* 너비를 100%로 설정 */ 
}

/* 채팅 컨테이너 */
.chat-container {
  width: 100%; /* 너비를 100%로 설정 */
  height: 100%; /* 높이를 고정된 값으로 설정 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 박스 크기를 계산 */
  resize: none; /* 크기 조절 비활성화 */ 
  border-radius: 30px; /* 테두리 모서리를 30px 둥글게 설정 */
  font-size: 16px; /* 글자 크기를 16px로 설정 */
  overflow-y: hidden; /* 세로 스크롤 숨김 */ 
}

.chat-container:focus {
  outline: none; /* 포커스 시 아웃라인 제거 */
  box-shadow: none; /* 포커스 시 그림자 제거 */
  background-color: #e0e0e0; /* 포커스 시 배경 색상을 연한 회색으로 변경 */
}

/* 채팅 상자 버튼 */
.chat-box-button {
  position: absolute; /* 위치를 절대적으로 설정 */
  right: 10px; /* 버튼을 텍스트 영역의 오른쪽 가장자리에 배치 */
  bottom: 50%; /* 아래쪽 여백을 중앙 정렬 */
  transform: translateY(50%); /* 수직 중앙 정렬 */
  padding: 5px 10px; /* 내부 여백을 줄임 */
  border: none; /* 테두리를 없음으로 설정 */
  color: rgb(0, 0, 0); /* 글자 색상을 검정으로 설정 */
  background-color: transparent; /* 배경 색상을 투명으로 설정 */
  cursor: pointer; /* 커서를 포인터로 변경 */
  border-radius: 20px; /* 테두리 모서리를 20px 둥글게 설정 */
  font-size: 10px; /* 글자 크기를 조정 */
}

/* 채팅 상자 버튼 호버 효과 */
.chat-box-button:hover {
  background-color: gainsboro; /* 배경 색상을 밝은 회색으로 변경 */
}
